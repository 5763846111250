import { cn } from "@rocket-resume/utils";
import { FileText } from "@phosphor-icons/react";

type Props = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  start?: React.ReactNode;
  end?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const BaseListItem = ({ title, description, start, end, className, onClick }: Props) => (
  <div
    className={cn(
      "flex flex-col 2xl:flex-row justify-between cursor-pointer items-center rounded p-3 2xl:p-6 transition-colors hover:bg-secondary/50 border border-dashed border-[#575757]",
      className,
    )}
    onClick={onClick}
  >
      {/* <div className="flex items-center space-x-4 border border-pink-950"> */}
        <div className="flex truncate text-[18px] items-center gap-2 2xl:gap-6"><FileText size={28} /> {title}</div>
        <div className="hidden text-[15px] opacity-75 sm:block">{description}</div>
      {/* </div> */}

      {/* {end && <div className="flex size-5 items-center justify-center">{end}</div>} */}
  </div>
);
